import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { Desktop } from './desktop';
import { Mobile } from './mobile';
import { getLocalStoreFrontSlug } from '../../utils';
import { Context } from '../../store';
import { AttentionBar } from '../attention-bar';

export const AppBar = ({ zipCode }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const { state } = useContext(Context);
  const { config } = state || {};

  const storeFrontSlug = getLocalStoreFrontSlug();

  const navRoutes = useMemo(() => {
    const routes = [
      { label: 'home', url: `/${storeFrontSlug}`, id: 'nav-home' },
      { label: 'brands', url: `/${storeFrontSlug}/brands`, id: 'nav-brands' },
      { label: 'deals', url: `/${storeFrontSlug}/deals`, id: 'nav-deals' },
    ];

    if (config?.helpCenter?.url)
      routes.push({
        label: 'support',
        url: config?.helpCenter?.url,
        target: '_blank',
        id: 'nav-support',
      });
    return routes;
  }, [storeFrontSlug, config?.helpCenter?.url]);

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    setIsVisible(currentScrollY <= lastScrollY || currentScrollY < 100);
    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <header
      className={`l-0 fixed top-0 z-10 w-full bg-white transition-transform duration-300 ${isVisible ? 'translate-y-0' : '-translate-y-full'}`}
    >
      {!zipCode && <AttentionBar />}
      <Desktop navRoutes={navRoutes} />
      <Mobile navRoutes={navRoutes} />
    </header>
  );
};
